import { useMutation } from "@apollo/client";
import { Button } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { GET_STATEMENTS, REMOVE_STATEMENT } from "../../constants";

const DeleteActionUtil = ({ id }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [removeStatement, { loading }] = useMutation(REMOVE_STATEMENT, {
    refetchQueries: [GET_STATEMENTS],
  });

  const handleOnClick = async () => {
    if (window.confirm("確定刪除?")) {
      try {
        await removeStatement({ variables: { id } });
        enqueueSnackbar("成功", {
          variant: "success",
        });
      } catch (err) {
        enqueueSnackbar("失敗", {
          variant: "error",
        });
        console.log(err);
      }
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleOnClick}
        disabled={loading}
      >
        刪除
      </Button>
    </>
  );
};

export default DeleteActionUtil;
