import React from 'react'

const AsyncStringField = ({value, loading, fieldName}) => {

    

    return (
        <>
            {loading ? 
                <span>載入中...</span> 
                : 
                value && value.reports.length > 0 ? <span>{value.reports[0][fieldName]}</span> : '--' 
            }
            
        </>
    )
}

export default AsyncStringField
