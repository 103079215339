import { Typography, Grid, CircularProgress } from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import EnhancedTable from "../components/Datatable/EnhancedTable";
import dayjs from "dayjs";
import AmountField from "../components/TableUtils/AmountField";
import { Link } from "react-router-dom";
import { GET_REPORTS } from "../constants";
import { useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaw } from "@fortawesome/free-solid-svg-icons";
import AsyncStringField from "../components/TableUtils/AsyncStringField";

const Home = () => {
  const {
    loading,
    error,
    data,
    refetch: dailyDataRefetch,
  } = useQuery(GET_REPORTS);
  const {
    loading: monthLoading,
    data: monthData,
    refetch: monthDataRefetch,
  } = useQuery(GET_REPORTS, {
    variables: { date: dayjs(new Date()).format("YYYY-MM-DD"), type: "month" },
  });

  useEffect(() => {
    dayjs.locale("zh-hk");
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    dailyDataRefetch();
    monthDataRefetch();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "日期",
        accessor: "date",
        Cell: ({ cell: { value } }) => (
          <div>
            <Link to={`/daily/${dayjs(value).format("YYYY-MM-DD")}`}>
              {dayjs(value).format("YYYY-MM-DD")}
            </Link>
          </div>
        ),
      },
      {
        Header: "支出",
        accessor: "total_all_expenses",
      },
      {
        Header: "收入",
        accessor: "total_all_income",
      },
      {
        Header: "日結",
        accessor: "total_amount",
        Cell: ({ cell: { value } }) => (
          <div>
            <AmountField value={value} />
          </div>
        ),
      },
    ],
    []
  );

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            {new Date().getMonth() + 1}月結算
          </Typography>
          <Typography style={{ fontSize: "14px" }} align="center">
            ({dayjs(new Date()).format("YYYY-MM-DD")})
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography>
            收入: $
            <span className={"text-blue"}>
              <AsyncStringField value={monthData} loading={monthLoading} fieldName="total_all_income" />
            </span>
          </Typography>
          <Typography>
            支出: $
            <span className={"text-primary"}>
            <AsyncStringField value={monthData} loading={monthLoading} fieldName="total_all_expenses" />
            </span>
          </Typography>
          <div className="foodpanda-border">
            <Typography>
              外賣APP收入:{" "}
              <span className={"text-foodpanda"}>
                $
                <AsyncStringField value={monthData} loading={monthLoading} fieldName="total_foodpanda_income" />
              </span>
            </Typography>
            <div>
              (foodpanda + deliveroo)
            </div>
          </div>
        </Grid>

        <Grid item xs={5}>
          <Typography variant="h6">總計:</Typography>
          <Typography variant="h6">
            $
            <AmountField
              value={
                !monthLoading && monthData && monthData.reports && monthData.reports.length > 0
                  ? monthData.reports[0].total_amount
                  : 0
              }
            />
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {loading || error ? (
            <CircularProgress color="secondary" />
          ) : (
            <EnhancedTable
              columns={columns}
              data={data.reports}
              skipPageReset={false}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
