import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  TextField,
  Button,
  Typography,
  Grid,
  MenuItem,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import { IncomeSchema } from "../../lib/schemas/FormSchema";
import { HomeStoreContext } from "../../lib/stores/HomeStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_STATEMENT,
  GET_ITEMS,
  GET_REPORTS,
  GET_STATEMENTS,
} from "../../constants";

dayjs.locale("zh-hk");

const IncomeForm = () => {
  const { setShowModal } = useContext(HomeStoreContext);
  const { enqueueSnackbar } = useSnackbar();
  const [submitAndClose, setSubmitAndClose] = useState(false);
  const [filtedIncomeSelect, setFiltedIncomeSelect] = useState([]);
  const { loading, data } = useQuery(GET_ITEMS);
  const [createIncomeStatement, { loading: muLoading }] = useMutation(
    CREATE_STATEMENT,
    {
      refetchQueries: [GET_REPORTS, GET_STATEMENTS],
    }
  );

  const formik = useFormik({
    initialValues: {
      date: dayjs(new Date()).format("YYYY-MM-DD"),
      item_id: "",
      amount: "",
    },
    validationSchema: IncomeSchema,
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        await createIncomeStatement({
          variables: {
            date: values.date,
            type: "income",
            item_id: values.item_id,
            amount: Number(values.amount),
          },
        });
        // formik.resetForm();
        if (submitAndClose) setShowModal(false);
        enqueueSnackbar("成功", {
          variant: "success",
        });
      } catch (err) {
        enqueueSnackbar("失敗", {
          variant: "error",
        });
        console.log(err);
      }
    },
  });

  useEffect(() => {
    if (data) {
      const filteredItems = data.items.filter(
        (item) => item.name === "foodpanda" || item.name === "門市" || item.name === "deliveroo"
      );
      setFiltedIncomeSelect(filteredItems);
    }
  }, [data]);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5">
              <FontAwesomeIcon icon={faDollarSign} /> 新增收入
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="date"
              name="date"
              label="日期"
              type="date"
              variant="outlined"
              value={formik.values.date}
              onChange={formik.handleChange}
              error={formik.touched.date && Boolean(formik.errors.date)}
              helperText={formik.touched.date && formik.errors.date}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              select
              id="item_id"
              name="item_id"
              label="項目"
              variant="outlined"
              value={formik.values.item_id}
              onChange={formik.handleChange}
              error={formik.touched.item_id && Boolean(formik.errors.item_id)}
              helperText={formik.touched.item_id && formik.errors.item_id}
            >
              {!loading ? (
                filtedIncomeSelect.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={""}>載入中...</MenuItem>
              )}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="amount"
              name="amount"
              label="收入"
              variant="outlined"
              type="tel"
              value={formik.values.amount}
              onChange={formik.handleChange}
              error={formik.touched.amount && Boolean(formik.errors.amount)}
              helperText={formik.touched.amount && formik.errors.amount}
            />
          </Grid>

          <Grid item xs={6}>
            <Button
              color="secondary"
              variant="contained"
              fullWidth
              type="submit"
              onClick={() => setSubmitAndClose(false)}
              style={{ color: "white" }}
              disabled={muLoading}
            >
              儲存及下一個
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              onClick={() => setSubmitAndClose(true)}
              disabled={muLoading}
            >
              儲存
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default IncomeForm;
