import React from "react";
import { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import dayjs from "dayjs";
import { CircularProgress } from "@material-ui/core";
dayjs.locale("zh-hk");

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const MonthlyChart = ({ title, data, loading }) => {
  const [chartData, setChartData] = useState([]);
  const [dataLabel, setDataLabel] = useState([]);

  useEffect(() => {
    if (data) {
      const labels = data.reports.map((item) => dayjs(item.date).format("DD"));
      const contents = data.reports.map((item) => item.total_amount);
      setDataLabel(labels);
      setChartData(contents);
    }
  }, [data]);

  const a = {
    labels: dataLabel,
    datasets: [
      {
        label: "日結",
        data: chartData,
        fill: false,
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgba(255, 99, 132, 0.2)",
      },
    ],
  };

  return (
    <div>
      <div className="header">
        <h1 className="title">{title}</h1>
      </div>
      {loading ? (
        <CircularProgress color="secondary" />
      ) : (
        <Line data={a} options={options} />
      )}
    </div>
  );
};

export default MonthlyChart;
