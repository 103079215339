import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import AuthRouteWithLayout from "./components/AuthRouteWithLayout";
import AuthLayout from "./layouts/AuthLayout";
import Home from "./views/Home";
import Signin from "./views/Signin";
import Daily from "./views/Daily";
import Report from "./views/Report";
import ReportExport from "./views/ReportExport";
import { ApolloProvider } from "@apollo/client";
import apolloClient from "./apollo-client";

const theme = createTheme({
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Noto Sans TC"',
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ],
    h5: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 600,
    },
  },
  palette: {
    primary: {
      light: "#4074a8",
      main: "#115293",
      dark: "#0b3966",
      contrastText: "#fff",
    },
    secondary: {
      light: "#4dabf5",
      main: "#4CB3E6", // bootstrap info
      dark: "#1769aa",
      contrastText: "#000",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <ApolloProvider client={apolloClient}>
          <Router>
            <Switch>
              <Route path="/signin">
                <Signin />
              </Route>
              <AuthRouteWithLayout
                layout={AuthLayout}
                component={Home}
                path="/"
                exact
              />
              <AuthRouteWithLayout
                layout={AuthLayout}
                component={Daily}
                path="/daily/:date"
                exact
              />
              <AuthRouteWithLayout
                layout={AuthLayout}
                component={Report}
                path="/report"
                exact
              />
              <AuthRouteWithLayout
                layout={AuthLayout}
                component={ReportExport}
                path="/report_export"
                exact
              />
            </Switch>
          </Router>
        </ApolloProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
