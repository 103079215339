import { gql } from "@apollo/client";

export const GET_ITEMS = gql`
  query {
    items {
      id
      name
    }
  }
`;

export const CREATE_STATEMENT = gql`
  mutation CreateStatement(
    $date: String!
    $amount: Float!
    $type: String!
    $item_id: Int!
  ) {
    createStatement(
      createStatementInput: {
        date: $date
        amount: $amount
        type: $type
        item_id: $item_id
      }
    ) {
      amount
    }
  }
`;

export const REMOVE_STATEMENT = gql`
  mutation RemoveStatement($id: Int!) {
    removeStatement(id: $id) {
      id
    }
  }
`;

export const GET_STATEMENTS = gql`
  query Statements($date: String) {
    statements(date: $date) {
      id
      date
      amount
      type
      item {
        id
        name
      }
    }
  }
`;

export const GET_REPORTS = gql`
  query Reports($date: String, $type: String) {
    reports(date: $date, type: $type) {
      date
      total_all_income
      total_all_expenses
      total_foodpanda_income
      total_foodpanda_expenses
      total_amount
    }
  }
`;

export const GET_ALL_REPORTS = gql`
  query {
    allReports {
      date
      name
      total
    }
  }
`;
