import React from "react";

const AmountField = (props) => {
  const { value } = props;

  return (
    <>
      <span className={value >= 0 ? "text-success" : "text-error"}>
        {value}
      </span>
    </>
  );
};

export default AmountField;
