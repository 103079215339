import * as Yup from "yup";

export const ExpensesSchema = Yup.object().shape({
  date: Yup.string().required("請輸入日期"),
  item_id: Yup.string().required("請輸入項目"),
  amount: Yup.string()
    .max(8)
    .required("請輸入支出")
    .matches(/^[0-9]+$/, "只可以輸入數字"),
});

export const IncomeSchema = Yup.object().shape({
  date: Yup.string().required("請輸入日期"),
  item_id: Yup.string().required("請輸入項目"),
  amount: Yup.string()
    .max(8)
    .required("請輸入收入")
    .matches(/^[0-9]+$/, "只可以輸入數字"),
});
