import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import HomeIcon from "@material-ui/icons/Home";
import PaymentIcon from "@material-ui/icons/Payment";
import TimelineIcon from "@material-ui/icons/Timeline";
import { HomeStoreContext } from "../../lib/stores/HomeStore";
import { useHistory } from "react-router-dom";
import { ChatRounded } from "@material-ui/icons";

const useStyles = makeStyles({
  root: {
    width: "100%",
    position: "fixed",
    bottom: 20,
    borderTop: "2px solid #115293",
    borderBottom: "2px solid #115293",
    zIndex: 20,
  },
  button: {
    color: "#115293",
  },
});

const SimpleBottomNavigation = () => {
  const classes = useStyles();
  const history = useHistory();
  const { setModalType, setShowModal } = useContext(HomeStoreContext);

  return (
    <BottomNavigation showLabels className={classes.root}>
      <BottomNavigationAction
        className={classes.button}
        label="主頁"
        icon={<HomeIcon />}
        onClick={() => history.push("/")}
      />
      <BottomNavigationAction
        className={classes.button}
        label="新增收入"
        icon={<MonetizationOnIcon />}
        onClick={() => {
          setShowModal(true);
          setModalType("income");
        }}
      />
      <BottomNavigationAction
        className={classes.button}
        label="新增支出"
        icon={<PaymentIcon />}
        onClick={() => {
          setShowModal(true);
          setModalType("expenses");
        }}
      />
      <BottomNavigationAction
        className={classes.button}
        label="統計"
        icon={<TimelineIcon />}
        onClick={() => history.push("/report")}
      />
      <BottomNavigationAction
        className={classes.button}
        label="項目報告"
        icon={<ChatRounded />}
        onClick={() => history.push("/report_export")}
      />
    </BottomNavigation>
  );
};

export default SimpleBottomNavigation;
