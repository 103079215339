import React from "react";

const TypeField = ({ value }) => {
  return (
    <>
      <span className={value === "income" ? "text-blue" : "text-primary"}>
        {value === "income" ? "收入" : "支出"}
      </span>
    </>
  );
};

export default TypeField;
