import React, { useContext } from "react";
import { Container, makeStyles } from "@material-ui/core";
import { HomeStoreContext } from "../lib/stores/HomeStore";
import Navbar from "../components/Nav/Navbar";
import SimpleBottomNavigation from "../components/Nav/SimpleBottomNavigation";
import { observer } from "mobx-react-lite";
import IncomeForm from "../components/Forms/IncomeForm";
import ExpensesForm from "../components/Forms/ExpensesForm";
import PopUp from "../components/PopUp";
const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "100px",
  },
}));

const AuthLayout = (props) => {
  const { children } = props;
  const classes = useStyles();
  const { showModal, setShowModal, modalType } = useContext(HomeStoreContext);

  return (
    <div className={classes.root}>
      <div style={{ marginBottom: "20px" }}>
        <Navbar />
      </div>
      <Container>{children}</Container>
      <SimpleBottomNavigation />
      <PopUp open={showModal} handleClose={() => setShowModal(false)}>
        {modalType === "income" ? <IncomeForm /> : <ExpensesForm />}
      </PopUp>
    </div>
  );
};

export default observer(AuthLayout);
