import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Toolbar, Avatar, AppBar, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import logo from "../../static/images/logo192.png";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // marginLeft: "0.8rem",
    // marginRight: "0.8rem",
    width: "100%",
    backgroundColor: theme.palette.primary.main
  },
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "between",
    color: "white",
    paddingLeft: "0.8rem"
  },
  title: {
    cursor: "pointer",
  },
}));

const Navbar = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.root}>
      <div className={classes.logo} onClick={() => history.push("/")}>
        <Avatar
          alt="Remy Sharp"
          src={logo}
          style={{ marginRight: "0.5rem" }}
        />
        <h2 className={classes.title}>Sushii</h2>
      </div>
      <div style={{paddingRight: "0.8rem"}}>
        <Button variant="contained" onClick={() => {
          localStorage.removeItem("token")
          history.push("/signin")
        }}>登出</Button>
      </div>
    </div>
  );
};

export default Navbar;
