import React, { useEffect, useMemo } from "react";
import { Grid, Button, Typography, CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import EnhancedTable from "../components/Datatable/EnhancedTable";
import { useQuery } from "@apollo/client";
import DeleteActionUtil from "../components/TableUtils/DeleteActionUtil";
import { GET_STATEMENTS } from "../constants";
import TypeField from "../components/TableUtils/TypeField";

const Daily = (props) => {
  const { match } = props;
  const history = useHistory();

  const { loading, error, data } = useQuery(GET_STATEMENTS, {
    variables: { date: match.params.date },
  });

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "No.",
        accessor: "id",
      },
      {
        Header: "類別",
        accessor: "type",
        Cell: ({ cell }) => (
          <div>
            <TypeField value={cell.row.original.type} />
          </div>
        ),
      },
      {
        Header: "項目",
        accessor: "item.name",
      },
      {
        Header: "總數",
        accessor: "amount",
      },
      {
        Header: " ",
        Cell: ({ cell }) => (
          <div>
            <DeleteActionUtil id={cell.row.original.id} />
          </div>
        ),
      },
    ],
    []
  );

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button
            size="large"
            variant="outlined"
            onClick={() => history.goBack()}
          >
            <FontAwesomeIcon
              icon={faChevronLeft}
              style={{ marginRight: "5px" }}
            />
            返回
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5">{match.params.date} 收支記錄</Typography>
        </Grid>

        <Grid item xs={12}>
          {loading || error ? (
            <CircularProgress color="secondary" />
          ) : (
            <EnhancedTable
              columns={columns}
              data={data.statements}
              skipPageReset={false}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Daily;
