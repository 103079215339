import React from "react";
import { Route, Redirect } from "react-router-dom";

const AuthRouteWithLayout = ({
  layout: Layout,
  component: Component,
  ...rest
}) => {
  const isAuthenticated = () => {
    const token = localStorage.getItem("token");

    if (token) {
      // const decodedToken = JwtDecode(token);

      // if (decodedToken.exp * 1000 < Date.now()) {
      //   localStorage.removeItem("token");
      //   return false;
      // }
      return true;
    }
    return false;
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
};

export default AuthRouteWithLayout;
