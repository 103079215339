import React, { useEffect, useMemo } from "react";
import { Grid, Button, Typography, CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faDownload } from "@fortawesome/free-solid-svg-icons";
import EnhancedTable from "../components/Datatable/EnhancedTable";
import { useQuery } from "@apollo/client";
import { GET_ALL_REPORTS } from "../constants";
import * as excelJS from "exceljs";
import { saveAs } from "file-saver";
import dayjs from "dayjs";
dayjs.locale("zh-hk");

const exportReport = (data) => {
  const workbook = new excelJS.Workbook();
  workbook.creator = "test";
  workbook.created = new Date();
  workbook.modified = new Date();

  const sheet = workbook.addWorksheet("項目報告");
  sheet.getRow(1).values = ["日期", "項目", "總數"];
  data.forEach((item) => {
    sheet.addRow([item.date, item.name, item.total]).commit();
  });

  workbook.xlsx.writeBuffer().then(function (buffer) {
    const blob = new Blob([buffer], { type: "applicationi/xlsx" });
    const filename = `sushii_report_${dayjs(new Date()).format(
      "YYYY-MM-DD"
    )}.xlsx`;
    saveAs(blob, filename);
  });
};

const ReportExpo = (props) => {
  const { match } = props;
  const history = useHistory();

  const { loading, error, data } = useQuery(GET_ALL_REPORTS, {});

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "日期",
        accessor: "date",
      },
      {
        Header: "項目",
        accessor: "name",
      },
      {
        Header: "總數",
        accessor: "total",
      },
    ],
    []
  );

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button
            size="large"
            variant="outlined"
            onClick={() => history.goBack()}
          >
            <FontAwesomeIcon
              icon={faChevronLeft}
              style={{ marginRight: "5px" }}
            />
            返回
          </Button>
          <Button
            style={{ marginLeft: "10px" }}
            size="large"
            variant="contained"
            color="primary"
            onClick={() => exportReport(data.allReports)}
          >
            <FontAwesomeIcon icon={faDownload} style={{ marginRight: "5px" }} />
            匯出Excel
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5">{match.params.date} 項目報告</Typography>
        </Grid>

        <Grid item xs={12}>
          {loading || error ? (
            <CircularProgress color="secondary" />
          ) : (
            <EnhancedTable
              columns={columns}
              data={data.allReports}
              skipPageReset={false}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default ReportExpo;
