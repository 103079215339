import { action, makeObservable, observable } from "mobx";
import { createContext } from "react";
import dayjs from "dayjs";
dayjs.locale("zh-hk");

class HomeStore {
  showModal = false;
  modalType = "income";
  selectedReportDate = dayjs(new Date()).format("YYYY-MM");
  constructor() {
    makeObservable(this, {
      showModal: observable,
      modalType: observable,
      selectedReportDate: observable,
      setShowModal: action,
      setModalType: action,
      clearStore: action,
    });
  }

  clearStore = () => {
    this.showModal = false;
  };

  setShowModal = (state) => {
    this.showModal = state;
  };

  setSelectedReportDate = (date) => {
    this.selectedReportDate = date;
  };

  setModalType = (type) => {
    this.modalType = type;
  };
}

export const HomeStoreContext = createContext(new HomeStore());
